<template>
  <div class="bg-img padding-90 min-height">
    <div class="width-100 padding-90 br-mid margin-b bg-white">
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-80 title">
          <p class="">未成年人信息</p>
        </div>
      </div>
      <div class="width-100 margin-bs flex-row justify-content-start align-items-center">
        <p class="fs-mid width-20 darkgrey">{{ data.childName }}</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.childGender == '1'">男</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.childGender == '2'">女</p>
        <p class="fs-mid width-20 darkgrey">{{ data.childHometown }}</p>
      </div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">学校/单位：</span>{{ data.childOrg }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">联系方式：</span>{{ data.childMobile }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">身份证号：</span>{{ data.childIdCard }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">户籍地：</span>{{ data.childHometown }}</div>
      <div class="width-100 margin-b"><span class="fs-mid darkgrey">地址：</span>{{ data.childAddress }}</div>
      <div class="divider-h margin-b"></div>
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-80 title">
          <p class="">未成年人父母信息</p>
        </div>
      </div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">父亲姓名：</span>{{ data.fatherName }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">父亲联系方式：</span>{{ data.fatherMobile }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">父亲住址/单位：</span>{{ data.fatherOrg }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">母亲姓名：</span>{{ data.motherName }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">母亲联系方式：</span>{{ data.motherMobile }}</div>
      <div class="width-100 margin-b"><span class="fs-mid darkgrey">母亲住址/单位：</span>{{ data.motherOrg }}</div>
      <div class="divider-h margin-b"></div>

      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center" v-show="data.isAccompany == 'true'">
        <div class="width-40 title">
          <p class="">陪同人员信息</p>
        </div>
      </div>
      <div class="width-100 margin-bs" v-show="data.isAccompany == 'true'"><span class="fs-mid darkgrey">姓名：</span>{{ data.accompanyName }}</div>
      <div class="width-100 margin-bs" v-show="data.isAccompany == 'true'">
        <span class="fs-mid darkgrey">联系方式：</span>{{ data.accompanyMobile }}
      </div>
      <div class="width-100 margin-b" v-show="data.isAccompany == 'true'">
        <span class="fs-mid darkgrey">住址/单位：</span>{{ data.accompanyOrg }}
      </div>
      <div class="divider-h margin-b" v-show="data.isAccompany == 'true'"></div>

      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">就诊信息</p>
        </div>
      </div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">就诊病症：</span>{{ data.diagnoses }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">就诊科室：</span>{{ data.diagnosesDept }}</div>
      <div class="width-100 margin-b" v-if="data.compliance"><span class="fs-mid darkgrey">符合情形：</span></div>
      <div class="width-100 margin-b" v-for="item in complianceCut(data.compliance)" :key="item">{{ item }}</div>
      <div class="divider-h margin-b"></div>

      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">侵害情况</p>
        </div>
      </div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">侵害时间：</span>{{ data.encroachTime }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">侵害地点：</span>{{ data.encroachLocation }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">侵害人：</span>{{ data.perpetrator }}</div>
      <div class="width-100 margin-bs"><span class="fs-mid darkgrey">事件概况：</span></div>
      <p class="width-100 fs-mid black margin-bs">
        {{ data.reportContent }}
      </p>
      <div class="width-100 flex-row justify-content-spaceBetween align-items-star flex-wrap" v-if="data.reportImg">
        <van-image class="margin-bs" width="31%" height="5rem" fit="contain" v-for="item in imageCut(data.reportImg)" :key="item" :src="item" />
      </div>
    </div>
    <div class="width-100 padding-90 br-mid bg-white" v-if="data.reportStatus == '1'">
      <div class="width-100 position-relative margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="block position-absolute"></p>
          <p class="">事件处理回复</p>
        </div>
        <p class="width-60 textAlign-r darkgrey">待回复</p>
      </div>
    </div>
    <div class="width-100 padding-90 br-mid bg-white" v-else-if="data.reportStatus == '2'">
      <div class="width-100 position-relative margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="block position-absolute"></p>
          <p class="">事件处理回复</p>
        </div>
        <p class="width-60 textAlign-r darkgrey">{{ data.updateTime }}</p>
      </div>
      <p class="fs-mid margin-b darkgrey line-height">
        {{ data.reportReply }}
      </p>
    </div>
    <div class="width-100 padding-90 br-mid bg-white" v-else>
      <div class="width-100 position-relative margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="block position-absolute"></p>
          <p class="">事件处理回复</p>
        </div>
        <p class="width-60 textAlign-r darkgrey">未回复</p>
      </div>
    </div>
  </div>
</template>
<script>
import { dictGet, mandatoryReportShow } from '@/api/reportApi';

export default {
  name: 'mandatoryReportingInfo',
  data() {
    return {
      data: {},
      dictList: []
    };
  },
  created() {
    let id = this.$route.query.id;

    this.initData(id);

    dictGet('ht_mandatory_report_compliance').then((response) => {
      if (response.code == 200) {
        this.dictList = response.data;
      }
    });
  },
  methods: {
    initData(id) {
      mandatoryReportShow(id).then((response) => {
        this.data = response.data;
      });
    },
    imageCut(image) {
      let images = image.split(',');
      return images;
    },
    complianceCut(compliance) {
      if (!compliance) {
        return [];
      }
      let outList = [];
      let compliances = compliance.split(',');
      for (let c = 0; c < compliances.length; c++) {
        for (let i = 0; i < this.dictList.length; i++) {
          if (compliances[c] == this.dictList[i].dictValue) {
            outList.push(this.dictList[i].dictLabel);
            break;
          }
        }
      }

      return outList;
    }
  }
};
</script>

<style lang="less" scoped>
.bg-img {
  background: url('../../assets/image/bg2.png') round no-repeat;
  background-size: contain;
  background-color: rgb(237, 237, 237);
}
.title {
  line-height: 2rem;
  background: url('../../assets/image/5.png') left bottom no-repeat;
  background-size: contain;
  color: #4f9eea;
  font-weight: bold;
  p {
    //color: rgb(29,49,113);
    font-size: 1.2rem;
  }
}
.block {
  width: 6px;
  height: 24px;
  background-color: #4f9eea;
  left: -5.1%;
}
</style>
